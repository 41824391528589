.cseekbar {

    &__seek-bar {
        position: relative;
        width: 1400px;
        height: 325px;
        border-radius: 40px;
        
        background-repeat: no-repeat;
        background-position: center;
    }
    
    &__seek-bar-thumb {
        position: absolute;
        width: 332px;
        height: 325px;
        top: -174px;
        transform: translateX(-50%);
        
    }

}