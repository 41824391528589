.moon-global {

    &__right-deco {
        width: 177px;
        height: 1571px;
        position: absolute;
        z-index: 2;
        background-image: url('/img/moon-right-decoration.png');
        background-repeat: no-repeat;
        background-size: contain;
        right: 111px;
        top: 824px;
    }

}