.mars-mission {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;

  &__title {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/mars-mission/heading-zh.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__title-en {
    background-image: url("/img/mars-mission/heading-en.png");
  }

  &__mars {
    position: absolute;
    left: -15px;
    top: 946px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__mars-intro {
    position: absolute;
    top: 1585.24px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__CE1 {
    position: absolute;
    top: 1458px;
    left: 355px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running marsCe1Animation,
      2s ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE2 {
    position: absolute;
    top: 1335px;
    left: 1204px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running marsCe2Animation,
      -1000ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE3 {
    position: absolute;
    top: 2163px;
    left: 1292px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running marsCe3Animation,
      2430ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE4 {
    position: absolute;
    top: 2300px;
    left: 536px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running marsCe4Animation,
      -250ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__buttons {
    position: absolute;
    left: 0;
    bottom: 685px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 200px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("/img/btn_link.png");
      background-size: cover;
      background-position: center;
      width: 798px;
      height: 173px;
      text-decoration: none;
      font-size: 72px;
    }
  }

  &__overlay-btns {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;



  }

  &__overlay-btns-ce {
    pointer-events: initial;
    width: 506px;
    height: 169px;
    position: absolute;
    &1 {
      left: 103px;
      top: 1306.63px;
      transform: rotate(-53.1deg);
    }
    &2 {
      left: 1376px;
      top: 1146.63px;
      transform: rotate(42.1deg);
    }
    &4 {
      left: 223px;
      top: 2546.63px;
      transform: rotate(43.1deg);
    }
    &3 {
      left: 1496px;
      top: 2456.63px;
      transform: rotate(-48.1deg);
    }
  }

  &__shadow-under-button {
    width: 1319px;
    height: 1319px;
    position: absolute;
    background-image: url('../../img/mars-mission/shadow-under-button.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 1278px;
    left: 421px;
  }

}

@keyframes marsCe1Animation {
  0% {
    opacity: 0;
    top: 1758px;
    left: 766px;
  }
  100% {
    opacity: 1;
    top: 1458px;
    left: 355px;
  }
}

@keyframes marsCe2Animation {
  0% {
    opacity: 0;
    top: 1758px;
    left: 766px;
  }
  100% {
    opacity: 1;
    top: 1335px;
    left: 1204px;
  }
}

@keyframes marsCe3Animation {
  0% {
    opacity: 0;
    top: 1758px;
    left: 766px;
  }
  100% {
    opacity: 1;
    top: 2163px;
    left: 1292px;
  }
}

@keyframes marsCe4Animation {
  0% {
    opacity: 0;
    top: 1758px;
    left: 766px;
  }
  100% {
    opacity: 1;
    top: 2300px;
    left: 536px;
  }
}