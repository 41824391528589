.moon-mission {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: $pageContentZIndex;

  &__title {
    display: none;
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/moon-mission/heading.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__title-en {
    display: none;
    position: absolute;
    width: 1966px;
    height: 468px;
    left: 0;
    top: 355px;
    background-image: url("/img/moon-mission/heading-en.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__show-with-block {
    display: block;
  }

  &__moon {
    position: absolute;
    left: 0;
    top: 742px;
    width: 100%;
    height: 2550.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      transform: translateY(-80px);
    }
  }

  &__moon-intro {
    position: absolute;
    top: 1585.24px;
    left: 50%;
    transform: translateX(-50%);
    animation-fill-mode: both;
    animation-name: showWhenAppear;
    animation-duration: $moonTransition;
    animation-delay: $showWithDelayDuration;
  }

  &__CE1 {
    position: absolute;
    top: 1458px;
    left: 266px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce1Animation,
      2s ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE2 {
    position: absolute;
    top: 1218px;
    left: 846px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce2Animation,
      -1000ms ease-in-out 3500ms infinite reverse both running satFloating;
  }

  &__CE3 {
    position: absolute;
    top: 1426.28px;
    right: 271.31px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce3Animation,
      2430ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE4 {
    position: absolute;
    top: 1938.36px;
    right: 269.07px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce4Animation,
      -1500s ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE5 {
    position: absolute;
    top: 2306px;
    left: 836px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce5Animation,
      -250ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__CE6 {
    position: absolute;
    top: 2014px;
    left: 421px;
    animation: $moonTransition ease-in-out $moonTransition 1 normal both running ce6Animation,
    -800ms ease-in-out 3500ms infinite normal both running satFloating;
  }

  &__buttons {
    position: absolute;
    left: 0;
    bottom: 685px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 200px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("/img/btn_link.png");
      background-size: cover;
      background-position: center;
      width: 798px;
      height: 173px;
      text-decoration: none;
      font-size: 72px;
    }
  }

  &__buttons-div {
    display: inline-block;
    text-align: center;
  }

  &__floating-anim {
    animation-name: satFloating;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  &__floating-anim-g1 {
    animation-delay: -500ms;
  }

  &__floating-anim-g2 {
    animation-delay: -800ms;
  }

  &__overlay-btns {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;



  }

  &__overlay-btns-ce {
    pointer-events: initial;
    width: 506px;
    height: 169px;
    position: absolute;
    &1 {
      left: 83px;
      top: 1346.63px;
      transform: rotate(-53.1deg);
    }
    &2 {
      top: 941px;
      left: 826px;
    }
    &3 {
      left: 1576px;
      top: 1346.63px;
      transform: rotate(53.1deg);
    }
    &6 {
      left: 93px;
      top: 2406.63px;
      transform: rotate(53.1deg);
    }
    &5 {
      top: 2774px;
      left: 826px;
    }
    &4 {
      left: 1576px;
      top: 2406.63px;
      transform: rotate(-53.1deg);
    }
  }

  &__shadow-under-button {
    width: 1319px;
    height: 1319px;
    position: absolute;
    background-image: url('../../img/moon-mission/shadow-under-button.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 1278px;
    left: 421px;
  }

}

@keyframes ce1Animation {
  0% {
    opacity: 0;
    top: 1758px;
    left: 766px;
  }
  100% {
    opacity: 1;
    top: 1458px;
    left: 266px;
  }
}

@keyframes ce2Animation {
  0% {
    opacity: 0;
    top: 1818px;
  }
  100% {
    opacity: 1;
    top: 1218px;
  }
}

@keyframes ce3Animation {
  0% {
    opacity: 0;
    top: 1769px;
    right: 840px;
  }
  100% {
    opacity: 1;
    top: 1426.28px;
    right: 271.31px;
  }
}

@keyframes ce4Animation {
  0% {
    opacity: 0;
    top: 1658.36px;
    right: 829.07px;
  }
  100% {
    opacity: 1;
    top: 1938.36px;
    right: 269.07px;
  }
}

@keyframes ce5Animation {
  0% {
    opacity: 0;
    top: 1756px;
  }
  100% {
    opacity: 1;
    top: 2306px;
  }
}

@keyframes ce6Animation {
  0% {
    opacity: 0;
    top: 1714px;
    left: 921px;
  }
  100% {
    opacity: 1;
    top: 2014px;
    left: 421px;
  }
}