body {
  margin: 0;
  overflow: hidden;
  background: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'MStiffHeiHK', sans-serif;
  color: white;
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-user-drag: none;

  // cursor: none !important;
}

a {
  color: white;
  font-size: 5em;
}
