.moon-link-with-hk {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__title {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/moon-link-with-hk/title-zh.png");
    background-size: contain;
  }

  &__title-en {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/moon-link-with-hk/title-en.png");
    background-size: contain;
  }

  &__text-box-container {
    width: 1676px;
    height: 2402px;
    position: absolute;
    left: 0;
    top: 835px;
    background-image: url("/img/moon-link-with-hk/text-box-background.png");
    font-size: 50px;
    font-family: "NotoSansTC";
    .scrollable-view-with-fader__scroll-container{
      scroll-behavior: smooth;
    }
  }

  &__text-box-container-en {
    font-family: 'Exo2';
    letter-spacing: 0;

    .moon-link-with-hk__text-content {
      letter-spacing: 0;
    }
  }

  &__text-content {
    position: absolute;
    left: 184px;
    right: 25px;
    top: 94px;
    bottom: 234px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    color: #D5D5D5;
  }

  &__mini-title {
    font-weight: bold;
    text-decoration: underline;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
  }

  &__light-box-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(100px);
    transition: all linear $fastTransitionDuration;
    animation-duration: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(18, 21, 28, 0.43);
    z-index: 10;
    font-family: 'NotoSansTC';
    flex-direction: column;
  }

  &__lightbox-title {
    
    
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;
    text-decoration-line: underline;

    color: #D5D5D5;


  }

  &__lightbox-box-container {
    max-width: 1440px;
  }

  &__lightbox-box-container-en {
    font-family: 'Exo2';
    letter-spacing: 0;

    .moon-link-with-hk__lightbox-title, 
    .moon-link-with-hk__lightbox-pic-source, 
    .moon-link-with-hk__lightbox-p,
    .moon-link-with-hk__lightbox-pic-desc {
      letter-spacing: 0;
    }
  }

  &__lightbox-pic-source {
    font-style: normal;
    font-weight: 350;
    font-size: 32px;
    line-height: 180%;
    /* identical to box height, or 58px */
    letter-spacing: 0.1em;

    color: #FFFFFF;
  }

  &__lightbox-pic-source2 {
    font-style: normal;
    font-size: 32px;
    line-height: 180%;
    /* identical to box height, or 58px */
    letter-spacing: 0.1em;

    color: #FFFFFF;
  }


  &__lightbox-p{
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;

    color: #D5D5D5;


  }

  &__lightbox-pic-desc {

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;

    color: #D5D5D5;
    margin-top: 60px;


  }

  &__light-box-close-btn {
    margin-top: 138px;
    
    pointer-events: initial;
  }

  &__tab-box-container {
    width: 436px;
    height: 699px;
    position: absolute;
    right: -25px;
    top: 50%;
    background-image: url("/img/moon-vision/tab_box_background.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 5;
  }

  &__tab-box-content {
    display: flex;
    flex-direction: column;
    gap: 28px;
    font-size: 36px;
    font-weight: 800;
    padding-top: 68px;
    padding-left: 175px;
    font-family: 'NotoSansTC';

    > div {
      position: relative;
      padding: 40px 0;

      &::before {
        position: absolute;
        width: 134px;
        height: 68px;
        left: -132px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("/img/tab_dot_active.png");
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
      }

      &:not(.active) {
        opacity: 0.4;

        &::before {
          width: 58.06px;
          height: 60px;
          left: -105px;
          background-image: url("/img/tab_dot.png");
        }
      }
    }
  }

  &__tab-box-content-en {
    font-family: 'Exo2';
    gap: 55px;

    div {
      transform: translateX(-30px);

      &::before {
        transform: translateY(-50%) translateX(30px);
      }
    }
  }

  &__tab-box-tab {
    white-space: pre-wrap;
  }

  &__text-content-padder {
    height: 1800px;
  }
}
