.small-button {

    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    color: white;
    border: 4px solid white;
    background: rgba(255, 255, 255, 0.15);

}