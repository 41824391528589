.page-layout {
  display: block;
  width: 100%;
  height: 100%;

  &__background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('/img/bg_moon.jpg');
    z-index: 1;
  }

  &__small-lottie-container {
    position: absolute;
    width: 2313px;
    height: 2313px;
    z-index: 2;
    top: 81px;
    left: -79px;
    transition: all ease-in-out $moonTransition ;
    opacity: 0;
    transform: scale(1.5);
  }

  &__large-lottie-container {
    position: absolute;
    width: 4427px;
    height: 4427px;
    z-index: 2;
    top: -976px;
    left: -1136px;
    transition: all ease-in-out $moonTransition ;
    transform: scale(1.5);
  }

  &__small-lottie-moon-menu {
    opacity: 1;
    transform: scale(1);
  }

  &__small-lottie-moon-mission {
    opacity: 0.3;
    transform: translateY(400px) scale(1.19);
  }

  &__large-lottie-moon-menu{
    opacity: 1;
    transform: scale(1);
  }

  &__mars-background-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('/img/bg_mars.jpg');
    z-index: 1;
    transition: all ease-in-out $moonTransition;
    clip-path: polygon(1556px 0, 2160px 0, 2160px 3840px, 613px 3840px);
  }
  

  &__show-mars-bg {
    clip-path: polygon(0 0, 2160px 0, 2160px 3840px, -943px 3840px);
  }

  &__show-moon-bg {
    clip-path: polygon(3112px 0, 3716px 0, 3716px 3840px, 2169px 3840px);
  }

  &__to-idle-menu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 427px;
    height: 393px;
    background-image: url('/img/btn_home.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 200;
    transition: all ease-in-out $moonTransition;
  }

  &__to-menu {
    position: absolute;
    left: -154px;
    bottom: 1860px;
    width: 295px;
    height: 109px;
    background-image: url('/img/btn_return.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 999;
    pointer-events: none;
    opacity: 0;
    transition: all linear 500ms;
  }

  &__to-menu-visible {
    pointer-events: initial;
    opacity: 1;
  }

  &__to-menu.moved-up {
    bottom: 896px;
  }

  &__locale {
    position: absolute;
    width: 121px;
    height: 121px;
    bottom: 267px;
    left: 363px;
    z-index: 200;
    transition: all ease-in-out $moonTransition;
  }

  &__to-idle-menu.hidden {
    transform: translateX(-600px) translateY(480px);
  }

  &__locale.hidden {
    transform: translateX(-600px) translateY(480px);
  }

  &__locale-button {
    position: relative;
    width: 121px;
    height: 121px;
    background-image: url('/img/btn_locale.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 48px;
    }
  }

  &__rotating-moon-container {
    position: absolute;
    z-index: 1;
    clip-path: circle(48% at 50% 50%);
    transition: all ease-in-out $rotatingMoonTransition;
    opacity: 1;
    z-index: 3;
    video {
      width: 100%;
      height: 100%;
    }
  }

  &__rotating-moon-container.idle {
    width: 2300px;
    height: 2300px;
    left: -1294px;
    top: -268px;
  }

  &__rotating-moon-container.moon_menu{

    width: 1820px;
    height: 1820px;
    left: 178px;
    top: 300px;

  }

  &__rotating-moon-container.moon_mission {
    width: 1428px;
    height: 1428px;
    top: 1225px;
    left: 367px;
  }

  &__rotating-moon-container.moon_mission_intro {
    width: 1122px;
    height: 1122px;
    top: 2688px;
    left: 1070px;
  }

  &__rotating-moon-container.moon_mission_intro {
    animation-name: fadeOut;
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-delay: 3000ms;
  }

  &__rotating-moon-container.moon_hide2{
    width: 1820px;
    height: 1820px;
    left: 178px;
    top: 300px;
    opacity: 0;
    transition-duration: 300ms;
  }

  &__rotating-moon-container.moon_hide3{
    width: 1428px;
    height: 1428px;
    top: 1225px;
    left: 367px;
    opacity: 0;
    transition-duration: 300ms;
  }

  &__rotating-moon-container.moon_hide{

    width: 2300px;
    height: 2300px;
    left: -2300px;
    top:  -2300px;
    opacity: 0;

  }

  &__rotating-mars-container {
    position: absolute;
    z-index: 1;
    width: 2300px;
    height: 2300px;
    top: 1830px;
    left: 1243px;
    clip-path: circle(48% at 50% 50%);
    opacity: 1;
    transition: all ease-in-out $rotatingMoonTransition;
    z-index: 3;
    pointer-events: none;

    video {
      width: 100%;
      height: 100%;
    }
  }

  &__rotating-mars-container.mars_menu{

    width: 1820px;
    height: 1820px;
    left: 178px;
    top: 300px;

  }

  &__rotating-mars-container.mars_mission {
    width: 1417px;
    height: 1417px;
    top: 1233px;
    left: 374px;
  }

  &__rotating-mars-container.mars_mission_intro {
    width: 1122px;
    height: 1122px;
    top: 2688px;
    left: 1070px;
  }

  &__rotating-mars-container.mars_mission_intro {
    animation-name: fadeOut;
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-delay: 3000ms;
  }

  &__rotating-mars-container.mars_hide{

    width: 2300px;
    height: 2300px;
    left: 2190px;
    top: 3840px;
    opacity: 0;

  }

  &__rotating-mars-container.mars_hide2{
    width: 1820px;
    height: 1820px;
    left: 178px;
    top: 300px;
    opacity: 0;
    transition-duration: 300ms;
  }

  &__rotating-mars-container.mars_hide3{
    width: 1417px;
    height: 1417px;
    top: 1233px;
    left: 374px;
    opacity: 0;
    transition-duration: 300ms;
  }
  
}
