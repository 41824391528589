.mars-link-with-hk {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__title {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/mars-link-with-hk/title-zh.png");
    background-size: contain;
  }

  &__title-en {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/mars-link-with-hk/title-en.png");
    background-size: contain;
  }

  &__text-box-container {
    width: 1676px;
    height: 2402px;
    position: absolute;
    left: 0;
    top: 835px;
    background-image: url("/img/text_box_background.png");
    background-repeat: no-repeat;
    font-size: 50px;
    font-family: "NotoSansTC";
  }

  &__text-box-container-en {
    font-family: 'Exo2';
    letter-spacing: 0;

    .mars-link-with-hk__text-content {
      letter-spacing: 0;
    }
  }

  &__text-content {
    position: absolute;
    left: 184px;
    right: 25px;
    top: 94px;
    bottom: 234px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    color: #D5D5D5;
  }

  &__mini-title {
    font-weight: bold;
    text-decoration: underline;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
  }

  &__light-box-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(100px);
    transition: all linear $fastTransitionDuration;
    animation-duration: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(18, 21, 28, 0.43);
    z-index: 10;
    font-family: 'NotoSansTC';
    flex-direction: column;
  }

  &__lightbox-title {
    
    
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;

    color: #D5D5D5;


  }

  &__lightbox-box-container {
    max-width: 1440px;
  }

  &__lightbox-box-container-en {
    font-family: 'Exo2';
    letter-spacing: 0;

    .mars-link-with-hk__lightbox-title, 
    .mars-link-with-hk__lightbox-pic-source, 
    .mars-link-with-hk__lightbox-p,
    .mars-link-with-hk__lightbox-pic-desc {
      letter-spacing: 0;
    }
  }

  &__lightbox-pic-source {
    font-style: normal;
    font-weight: 350;
    font-size: 32px;
    line-height: 180%;
    /* identical to box height, or 58px */
    letter-spacing: 0.1em;

    color: #FFFFFF;
  }

  &__lightbox-pic-source2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 180%;
    /* identical to box height, or 58px */
    letter-spacing: 0.1em;

    color: #FFFFFF;
  }


  &__lightbox-p{
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;

    color: #D5D5D5;


  }

  &__lightbox-pic-desc {

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 180%;
    /* or 86px */
    letter-spacing: 0.1em;

    color: #D5D5D5;
    margin-top: 60px;


  }

  &__light-box-close-btn {
    margin-top: 138px;
    
    pointer-events: initial;
  }

  &__credit {
    font-size: 32px;
  }
}
