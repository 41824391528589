.scrollable-view-with-fader{ 

    &__container {
        position: relative;
    }

    &__scroll-container {
        height: 100%;
        overflow-y: auto;
        padding-right: 140px;
    }

    &__fader {
        height: 100px;
        position: absolute;
        width: calc(100% - 12px);
        z-index: 4;
    }

    &__upper-fader {
        pointer-events: none;
        background: linear-gradient(180deg, rgba(0,0,0, 0.3) 0%, rgba(0,0,0,0) 100%);
        top: -10px;
        left: 0;
    }

    &__lower-fader {
        pointer-events: none;
        background: linear-gradient(0deg, rgba(0,0,0, 0.3) 0%, rgba(0,0,0,0) 100%);
        bottom: -10px;
        left: 0;
    }

}