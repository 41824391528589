.customscroll {

    &::-webkit-scrollbar {
        background-color: rgba(219, 219, 219, 0.1);
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(#D9D9D9, #737373);
    }

}