.div-ts-by-locale {

    font-size: 72px;
    display: inline-block;
    text-align: center;
    white-space: pre-line;

    &__en {
        font-size: 64px;
        font-family: 'RussellSquare', sans-serif;
        padding: 0 40px;
    }

}