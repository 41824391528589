.moon-vision {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: 5;

  &__title {
    position: absolute;
    width: 1966px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/moon-vision/heading.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__title-en {
    background-image: url("/img/moon-vision/heading-en.png");
  }

  &__text-box-container {
    width: 1676px;
    height: 2400px;
    position: absolute;
    left: 0;
    top: 835px;
    background-image: url("/img/text_box_background.png");
    font-size: 50px;
  }

  &__text-content {
    position: absolute;
    left: 184px;
    right: 95px;
    top: 94px;
    bottom: 234px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    color: #D5D5D5;
    font-family: 'NotoSansTC';

    .scrollable-view-with-fader__scroll-container{
      scroll-behavior: smooth;
    }

    h2 {
      color: #B2A789;
    }

    h3 {
      position: relative;
      color: #fff;
      padding-left: 140px;
      

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '▶';
        color: #B2A789;
      }
    }

    img {
      margin: 15px 0;
    }

    ol {
      margin-left: 100px;
      img {
        width: 100%;
      }
    }
  }

  &__text-content-en  {
    font-family: 'Exo2';
    font-weight: 300;
    letter-spacing: 0;
  }

  &__tab-box-container {
    width: 436px;
    height: 699px;
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/img/moon-vision/tab_box_background.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__tab-box-content {
    display: flex;
    flex-direction: column;
    gap: 68px;
    font-size: 36px;
    font-weight: 800;
    padding-top: 98px;
    padding-left: 175px;
    font-family: 'NotoSansTC';

    > div {
      position: relative;
      padding: 40px 0;

      &::before {
        position: absolute;
        width: 134px;
        height: 68px;
        left: -132px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("/img/tab_dot_active.png");
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
      }

      &:not(.active) {
        opacity: 0.4;

        &::before {
          width: 58.06px;
          height: 60px;
          left: -105px;
          background-image: url("/img/tab_dot.png");
        }
      }
    }
  }

  &__tab-box-content-en {
    font-family: 'Exo2';
    gap: 24px;

    div {
      transform: translateX(-30px);

      &::before {
        transform: translateY(-50%) translateX(30px);
      }
    }
    
  }

  &__scroll-content-divider {
    height: 1px;
    width: 100%;
    background-color: white;
    border-radius: 999;
  }
  &__scroll-content-padder {
    height: 1500px;
  }
  
  &__photo-thanks {
    font-size: 32px;
  }

  &__margin-left {
    margin-left: 60px;
    font-weight: bold;
  }

  &__margin-left-en {
    margin-left: 90px;
  }
}
