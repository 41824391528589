
@keyframes showWhenAppear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.showAnimation {
    animation-name: showWhenAppear;
    animation-duration: $moonTransition;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

.showAnimationWithDelay {
    animation-delay: $showWithDelayDuration;
    animation-name: showWhenAppear;
    animation-duration: $moonTransition;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes delayPlaceholder {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
    
}

@keyframes satFloating {
    0% {
        transform: translateY(-8px);
    }

    50% {
        transform: translateY(8px);
    }

    100% {
        transform: translateY(-8px);
    }
}


@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.fadeOut {
    animation-name: fadeOut;
    animation-duration: 700ms;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}
