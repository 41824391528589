.moon-photo-gallery {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    z-index: 5;
    &__title {
        position: absolute;
        width: 1966px;
        height: 570px;
        top: 298px;
        background-image: url("/img/moon-photo-gallery/title_zh.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 100;
        pointer-events: none;
    }

    &__title-en {
        background-image: url("/img/moon-photo-gallery/title_en.png");
        pointer-events: none;
    }

    &__light-box {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(100px) brightness(0.5);
        transition: all linear $fastTransitionDuration;
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        pointer-events: none;
    }

    &__light-box.visible {
        opacity: 1;
        pointer-events: initial;
    }

    &__filter {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }

    &__space {
        display: grid;
        grid-template-columns: repeat(11, 974px);
        // transition: all linear 700ms;
        
        img {
            padding: 0;
            margin: 0;
            border: none;
            width: 974px;
            height: 1218px;
        }
        &.touched {
            transition-timing-function: ease-in-out;
            transition-duration: 10ms;
        }
    }

    &__space-container {
        position: relative;
        width: 10712px;
        height: 13390px;
    }

    &__photo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__photo-1 {
        position: absolute;
        width: 1521px;
        height: 1049px;
        left: 316px;
        top: 1662px;
    }

    &__light-box-content {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        img {
            pointer-events: initial;
            max-width: 1796px;
            max-height: 1920px;
        }
    }

    &__movement-detector {
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__space-click-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 10712px;
        height: 13390px;
    }

    &__space-click-cell{
        position: absolute;
    }

    &__space-click-cell- {
        &8 {
            top: 2560px;
            left: 1388px;
            width: 1983px;
            height: 1314px;
            
        }
    }

    &__light-box-desc {
        width: 100%;
        max-width: 1796px;
        margin-top: 60px;
    }

    &__light-box-desc-zh {
        font-family: 'NotoSansTC';
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 180%;
        /* or 86px */
        letter-spacing: 0.1em;
        color: #FFFFFF;
    }

    &__light-box-desc-en {
        font-family: 'Exo2';
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 160%;
        /* or 77px */
        color: #FFFFFF;
    }

    &__light-box-close-btn {
        // position: absolute;
        // bottom: 500px;
        margin-top: 80px;
    }

    &__credit {
        position: absolute;        
        right: 162px;
        width: 1103px;
        z-index: 3;
    }

    &__credit-en {
        width: 1180px;
        height: 211px;
        bottom: 102px;
        background-image: url('/img/moon-photo-gallery/credit-en.png');
        background-repeat: no-repeat;
    }

    &__credit-zh {
        width: 1141px;
        height: 151px;
        bottom: 132px;
        background-image: url('/img/moon-photo-gallery/credit-zh.png');
        background-repeat: no-repeat;
    }

    &__click-hint-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 400px;
        background-position: center;
        animation-delay: 5s;
        animation-name: fadeOut;
        animation-duration: 500ms;
        animation-fill-mode: both;
        animation-iteration-count: 1;
    }

    &__click-hint-container-zh {
        background-image: url('/img/moon-photo-gallery/tap-hints.png');
    }

    &__click-hint-container-en {
        background-image: url('/img/moon-photo-gallery/tap-hints-en.png');
    }

}
