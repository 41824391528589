.mars-menu {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: $pageContentZIndex;

  &__color-bar {
    position: absolute;
    top: 506px;
    left: 0;
    height: 110px;
    width: 100%;
    background: rgba(205, 62, 62, 0.5);
    z-index: 2;
  }

  &__mars-shadow {
    background-image: url('../../img/mars-menu/mars-shadow.png');
    background-size: cover;
    width: 100%;
    height: 2575px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__heading {
    position: absolute;
    top: 408px;
    left: 403px;
    z-index: $pageContentZIndex;
  }

  &__items {
    position: absolute;
    top: 2300px;
    left: 569px;
  }

  &__items {
    // transform: translateY(-120px);
    z-index: $pageContentZIndex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 58px;
  }

  &__item-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/img/mars-menu/btn_link.png");
    background-size: cover;
    background-position: center;
    width: 1019.8px;
    height: 235.17px;
    text-decoration: none;
  }

  &__mars {
    width: 100%;
    height: 2578.5px;
  }
}
