.moon-rover {

    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5;

    &__rover-title {
        width: 1966px;
        height: 570px;
        position: absolute;
        left: 2px;
        top: 298px;
        background-image: url("/img/moon-rover/title_zh.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__rover-title-en {
        background-image: url("/img/moon-rover/title_en.png");
    }

    &__rover-img-seq {
        width: 2162px;
        height: 1973px;
        object-fit: cover;
        position: absolute;
        left: -26px;
        top: 1161px;
        transition: all ease-in-out $transitionDuration;
        transform-origin: bottom center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__rover-img-seq.smaller {
        transform: scale(0.75) translateY(10%);
    }

    &__seek-bar {
        position: absolute;
        width: 1400px;
        height: 325px;
        left: 380px;
        right: 145px;
        bottom: 581px;
    }

    &__floating-point-container {
        display: inline-block;
        position: absolute;
        width: 200px;
        height: 200px;
        
        background-image: url("../../img/mars-rover/floating-point.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__floating-point-container.selected {
        background-image: url("../../img/mars-rover/floating-point-selected.png");
    }

    &__floating-point-container.point1 {
        top: 1795px;
        left: 1600px;
    }

    &__floating-point-container.point2 {
        top: 2270px;
        left: 1026px;
    }

    &__floating-point-container.point3 {
        top: 2099px;
        left: 464px;
    }

    &__solar-panel {
        display: flex;
        width: 1794px;
        position: absolute;
        top: 982px;
        left: 182px;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 153%;
        /* or 76px */
        letter-spacing: 0.01em;
        color: #FFFFFF;
        z-index: 20;
        opacity: 0;
        transition: opacity linear $transitionDuration;
    }

    &__solar-panel.visible {
        opacity: 1;
    }

    &__solar-ul-container {
        display: flex;
        justify-content: flex-start;

        ul {
            margin-top: 0;
        }
    }

    &__dotted-line-lottie-container {
        position: absolute;
        left: 0;
        top: 1198px;
        z-index: 10;
    }

    &__button-lottie-container {
        position: absolute;
        left: -41px;
        top: 1100px;
        z-index: 10;
        width: 2160px;
        height: 1500px;
        transform: scale(0.93);
        animation-name: showWhenAppear;
        animation-duration: 500ms;
        animation-delay: 1000ms;
        animation-iteration-count: 1;
        animation-fill-mode: both;
    }

    &__rover-button-container {
        display: inline-block;
        width: 80px;
        height: 80px;
        position: absolute;
        transform: scale(2.0);
        
    }

    &__rover-button {
        display: inline-block;
        width: 80px;
        height: 80px;
        pointer-events: none;
    }

    &__rover-button-inline {
        display: inline-block;
        width: 60px;
        height: 20px;
        pointer-events: none;
        transform: translateX(-15%) translateY(123%);
    }

    &__dotted-line-lottie-container-scene-7 {
        left: -25px;
    }

    &__dotted-line-lottie-container-scene-8 {
        left: -35px;
    }

    &__dotted-line-lottie-container-scene-9 {
        left: -35px;
    }

    &__dotted-line-lottie-container-scene-10 {
        left: -35px;
    }

    &__dotted-line-lottie-container-scene-11 {
        left: -40px;
    }

    &__dotted-line-lottie-container-scene-12 {
        left: -45px;
    }

    &__rover-instruction {
        position: absolute;
        top: 3292px;
        width: 100%;
        text-align: center;
        white-space: pre-wrap;
        left: 0;
        font-size: 40px;
        font-family: 'NotoSansTC';
    }

    &__lightbox-image {
        max-height: 1000px;
        object-fit: contain;
    }

    &__rover-instruction-en {
        font-family: 'Exo2';
        font-weight: 200;
    }

    &__shadow-bg {
        position: absolute;
        top: 1145px;
        height: 1958px;
        width: 1958px;
        left: 98px;
        background-image: url('/img/moon-rover/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__lightbox {

        &-outer {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            pointer-events: none;
            opacity: 0;
            transition: all linear 300ms;

            &.visible {
                pointer-events: initial;
                opacity: 1;
            }
        }

        &-share-prop {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        &-container {
            left: 198px;
            top: 853px;
            width: 1763px;
            height: 2452px;

            &-en {
                font-family: 'Exo2';
                letter-spacing: 0;
            }

            &-zh {
                font-family: 'NotoSansTC';
            }
        }

        &-inner-container {
            background: rgba(29, 45, 70, 0.4);
            backdrop-filter: blur(40px);
            width: 100%;
            height: 100%;
        }

        &-inner-dark-container {
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.4;
        }

        &-border-container {
            background-image: url('/img/moon-rover/lightbox-border.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &-scroll-view {
            position: absolute;
            top: 62px;
            right: 28px;
            bottom: 265px;
            left: 60px;
        }

        &-title {
            font-family: 'NotoSansTC';
            font-style: normal;
            font-weight: 900;
            font-size: 72px;
            line-height: 150%;
            /* or 108px */
            letter-spacing: 0.1em;
            
        }

        &-title-left-pane {
            flex: 1;
        }

        &-desc-container {
            display: flex;
            justify-items: center;
            font-size: 48px;
            font-weight: 500;
            align-items: center;
        }

        &-pre-dash {
            display: none;
            width: 100px;
            margin-right: 50px;
            height: 3px;
        }

        &-class-dot {
            width: 60px;
            height: 60px;
            border: 2px solid white;
            border-radius: 30px;
            margin-right: 30px;
        }

        &-image {
            width: 100%;
            margin-bottom: 26px;
        }

        &-title-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 22px;
        }

        &-title-type-pane {
            color: white;
            font-size: 50px;
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        &-bullets-container {
            font-size: 48px;
        }

        &-bullets-container-zh {
            li {
                line-height: 180%;
            }
        }

        &-bullets-container-en {
            li {
                line-height: 160%;
            }
        }

        &-photo-caption {
            min-height: 82px;
            font-size: 32px;
            white-space: pre-wrap;
        }

        &-close-btn {
            
            width: 109px; 
            height: 109px;
            position: absolute;
            bottom: 92px;
            left: 825px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('/img/mars-mission-intro/close-btn.png');
        }

        &-type-sp {
            .moon-rover__lightbox-title-left-pane {
                color: #71949E;
            }

            .moon-rover__lightbox-class-dot, .moon-rover__lightbox-pre-dash {
                background-color: #71949E;
            }
        }

        &-type-n {
            .moon-rover__lightbox-title-left-pane {
                color: #B2A789;
            }

            .moon-rover__lightbox-class-dot, .moon-rover__lightbox-pre-dash {
                background-color: #B2A789;
            }

            .moon-rover__lightbox-title-type-pane {
                display: none;
            }
        }

        &-container-en {
            .moon-rover__lightbox-title-row {
                letter-spacing: 0;
            }
            .moon-rover__lightbox-title {
                letter-spacing: 0;
            }
        }
    }

    &__lightbox-bullets-container ul {
        padding-left: 60px;
    }

}