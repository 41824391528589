.idle-menu {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: $pageContentZIndex;
  
  &__bg-decor-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__groups-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__moon-group {
    // background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 1503px;
    height: 2618px;
    // background-image: url('/img/landing/MoonGroup.png');
  }

  &__mars-group {
    position: absolute;
    right: 0;
    top: 1738px;
    width: 1590px;
    height: 2399px;
    // background-image: url('/img/landing/MarsGroup.png');
  }

  &__items {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__button-container {
    width: 962px;
    height: 2388px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__moon-container {
    left: -7px;
    top: 925px;
    // background-image: url('/img/landing/btn_moon.png');
    // background-position-y: 0px;
    z-index: 100;
  }

  &__mars-container {
    top: 1456px;
    right: 0;
    position: absolute;
    z-index: $pageContentZIndex;
  }

  &__moon-lottie {
    position: relative;
    top: 34px;
  }

  &__mars-lottie {
    position: relative;
    top: 324px;
    z-index: 4;

    * {
      position: relative;
      z-index: 4;
    }
  }

  &__lottie-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__lottie-deco1-container {
    transform: translateX(7.5px);
  }

  &__lottie-deco3-container {
    width: 368px;
    height: 1293px;
    position: absolute;
    top: 2236px;
    left: -66px;
  }

  &__lottie-deco4-container {
    width: 197px;
    height: 603px;
    position: absolute;
    top: 117px;
    right: 123px;
  }
  
  &__shadow-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__moon-shadow-layer {
    position: absolute;
    background-image: url('/img/landing/MoonShadow.png');
    width: 1503px;
    height: 2618px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    z-index: 2;
  }

  &__mars-shadow-layer {
    position: absolute;
    background-image: url('/img/landing/MarsShadow.png');
    width: 1503px;
    height: 2618px;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    z-index: 2;
  }

  &__upper-shadow-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    pointer-events: none;
  }

  

  &__moon-upper-shadow-layer {
    position: absolute;
    background-image: url('/img/landing/MoonLightingFilter.png');
    width: 935px;
    height: 1920px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
  }

  &__mars-upper-shadow-layer {
    position: absolute;
    background-image: url('/img/landing/MarsLightingFilter.png');
    width: 887px;
    height: 1918px;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
  }

  &__friendly {
    width: 670px;
    height: 213px;
    position: absolute;
    bottom: 126px;
    right: 162px;
    background-image: url('/img/landing/friendly.png');
    z-index: 120;
  }

}
