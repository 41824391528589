.ce-inline-button {

    &__container {
        padding: 8px;
        position: relative;
        
    }
    
    &__corner {
        width: 18px;
        height: 18px;
        background-size: 18px;
        background-repeat: no-repeat;
    }

    &__top-left{
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("../../img/ce-inline-button/top-left.png");
    }
    &__top-right{
        position: absolute;
        right: 0;
        top: 0;
        background-image: url("../../img/ce-inline-button/top-right.png");
    }
    &__bottom-left{
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("../../img/ce-inline-button/bottom-left.png");
    }
    &__bottom-right{
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url("../../img/ce-inline-button/bottom-right.png");
    }

    &__background-container {
        background: rgba(255, 255, 255);
        padding: 10px 32px;
        border: 1px solid rgba(255, 255, 255, 1);
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 8px;
        right: 8px;
    }

    $buttonDuration: 1000ms;

    &__text-container {
        animation-name: ce-line-button__textGlow;
        animation-duration: $buttonDuration;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: both;
        animation-timing-function: linear;
        margin: 16px;
        padding-left: 8px;
    }

    &__background_animation {
        animation-name: ce-line-button__buttonGlow;
        animation-duration:  $buttonDuration;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: both;   
        animation-timing-function: linear;
        animation-play-state: running;
    }

    &__background_animation-pause {
        animation-play-state: paused;
    }

}

@keyframes ce-line-button__buttonGlow {
    0% {
        opacity: 0.15;
        box-shadow: 0px 0px 0px #FFFFFF;
    }

    100% {
        opacity: 0.3;
        box-shadow: 0px 0px 35px #FFFFFF;
    }
}

@keyframes ce-line-button__textGlow {
    0% {
        text-shadow: 0px 0px 0px #FFFFFF;
    }

    100% {
        text-shadow: 0px 0px 35px #FFFFFF;
    }
}