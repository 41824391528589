@font-face {
  font-family: 'MStiffHeiHK';
  src: local('MStiffHeiHK Medium'), 
       url('../../fonts/MStiffHeiHK-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MStiffHeiHK';
  src: local('MStiffHeiHK UltraBold'), 
       url('../../fonts/MStiffHeiHK-UltraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MHeiHK';
  src: local('MHeiHK Medium'), 
       url('../../fonts/MHeiHK-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RussellSquare';
  src: url('../../fonts/RussellSquareStd.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansTC';
  src: url('../../fonts/NotoSansTC-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansTC';
  src: url('../../fonts/NotoSansTC-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('../../fonts/Exo2-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('../../fonts/Exo2-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('../../fonts/Exo2-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('../../fonts/Exo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}