.moon-menu-lbtn{ 

    &__container {
        position: relative;
        pointer-events: none;
    

        .div-ts-by-locale  {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &__en {
                padding: 0;
            }
        }
    }
    
}