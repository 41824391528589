.moon-menu {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: $pageContentZIndex;

  &__color-bar {
    position: absolute;
    width: 100%;
    height: 110px;
    top: 506px;
    left: 0;
    background: rgba(252, 191, 33, 0.3);
    z-index: 2;
  }

  &__moon-shadow {
    background-image: url('../../img/moon-menu/moon-shadow.png');
    background-size: 2060px;
    background-repeat: no-repeat;
    width: 2084px;
    height: 2084px;
    z-index: 2;
    position: absolute;
    top: 170px;
    left: 38px;
    background-position: center;
  }

  &__moon {
    width: 100%;
    height: 2457.5px;
  }

  &__heading {
    position: absolute;
    top: 408px;
    left: 403px;
    z-index: $pageContentZIndex;
  }

  &__items {
    position: absolute;
    top: 2300px;
    left: 569px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 58px;
    z-index: $pageContentZIndex;
  }

  &__items-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1019.8px;
    height: 235.17px;
    text-decoration: none;
    
  }

  &__item-div {
    display: inline-block;
    text-align: center;
  }
}
