.moon-mission-intro {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &__title {
    position: absolute;
    width: 2200px;
    height: 570px;
    left: 0;
    top: 298px;
    background-image: url("/img/moon-mission-intro/heading.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;

    &.y2007 {
      background-image: url("/img/moon-mission-intro/heading1.png");
    }

    &.y2010 {
      background-image: url("/img/moon-mission-intro/heading2.png");
    }

    &.y2013 {
      background-image: url("/img/moon-mission-intro/heading3.png");
    }

    &.y2014 {
      background-image: url("/img/moon-mission-intro/heading4.png");
    }

    &.y2018 {
      background-image: url("/img/moon-mission-intro/heading5.png");
    }

    &.y2020 {
      background-image: url("/img/moon-mission-intro/heading6.png");
    }
  }

  &__title-en {
    background-image: url("/img/moon-mission-intro/heading-en.png");

    &.y2007 {
      background-image: url("/img/moon-mission-intro/heading1-en.png");
    }

    &.y2010 {
      background-image: url("/img/moon-mission-intro/heading2-en.png");
    }

    &.y2013 {
      background-image: url("/img/moon-mission-intro/heading3-en.png");
    }

    &.y2014 {
      background-image: url("/img/moon-mission-intro/heading4-en.png");
    }

    &.y2018 {
      background-image: url("/img/moon-mission-intro/heading5-en.png");
    }

    &.y2020 {
      background-image: url("/img/moon-mission-intro/heading6-en.png");
    }
  }

  &__text-box-container {
    font-family: 'NotoSansTC';
    width: 1676px;
    height: 2400px;
    position: absolute;
    left: 0;
    top: 835px;
    background-image: url("/img/text_box_background.png");
    font-size: 50px;
    z-index: 2;
  }

  &__text-content {
    position: absolute;
    left: 184px;
    right: 25px;
    top: 94px;
    bottom: 234px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    color: #D5D5D5;
    max-height: 1550px;

    p {
      margin: 90px 0;
    }
    
    p:nth-of-type(1) {
      margin-top: 0;
    }

    li {
      padding-left: 40px;
    }

    li::marker {
        content: '▶';
        color: white;
    }
  }

  &__text-content-en {
    font-family: 'Exo2', sans-serif;
    font-weight: 300;
    font-size: 48px;
    line-height: 160%;
    letter-spacing: 0;
  }

  &__timeline {
    position: absolute;
    left: 0;
    top: 1905px;
    width: 100%;
    height: 3px;
    background-color: #696969;
  }

  &__timeline-year {
    position: absolute;
    top: 33px;
    width: 133px;
    height: 85px;
    font-family: 'NotoSansTC';
    font-size: 28px;
    color: #696969;
    text-align: center;

    &.active {
      width: 163px;
      height: 280px;
      top: 78px;
    }

    &::before {
      position: absolute;
      left: 50%;
      top: -50%;
      width: 21px;
      height: 21px;
      background-color: #696969;
      border-radius: 100%;
      transform: translateX(-50%);
      content: '';
    }

    &.active::before {
      background-color: #00000000;
    }

    &.y2007 {
      left: 192px;

      
    }

    &.y2010 {
      left: 370px;

      
    }

    &.y2013 {
      left: 548px;

      
    }

    &.y2014 {
      left: 726px;

      
    }

    &.y2018 {
      left: 904px;

      
    }

    &.y2020 {
      left: 1082px;

      
    }

    > div:nth-child(1) {
      font-size: 28px;
      width: 300px;
      transform: translateX(-80px) translateY(-10px);
      
    }

    &.active {
      > div:nth-child(1) {
        display: block;
        color: #FFF;
        font-size: 36px;
        font-weight: 800;
        transform: translateX(-85px) translateY(-10px);
      }
    }
  }

  &__click-block {
    display: inline-block;
    position: absolute;
    width: 156px;
    height: 245px;
    top: -100px;
    

    &.y2007 {
      left: 186px;
    }

    &.y2010 {
      left: 362px;
    }

    &.y2013 {
      left: 538px;
    }

    &.y2014 {
      left: 714px;
    }

    &.y2018 {
      left: 890px;
    }

    &.y2020 {
      left: 1066px;
    }
  }

  &__moon {
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url("/img/moon-mission-intro/moon.png");
    background-size: contain;
    width: 1456px;
    height: 1518px;
    z-index: 1;
    pointer-events: none;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
  }

  &__tag {
    width: fit-content;
    border: 3px solid #FFF;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 22px;
    letter-spacing: 4px;
  }

  &__light-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // backdrop-filter: blur(100px);
    background-image: url('/img/moon-mission-intro/03.1.4.2_Moon_Mission_03_02[CH]_lightboxBG.jpg');
    transition: all linear $fastTransitionDuration;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    // background: rgba(18, 21, 28, 0.43);
    z-index: 10;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__light-box-content {
    display: inline-flex;
    flex-direction: column;
    gap: 67px;
    font-size: 50px;
    max-width: 1433px;
    // max-height: 1412px;
    overflow: auto;
    z-index: 10;

    .moon-mission-intro__tag {
      margin-top: 70px;
    }

    img {
      width: auto;
      max-width: 1433px;
    }
  }

  &__light-box-close-btn {
    width: 109px !important;
    height: 109px !important;
    margin: 0 auto;
  }

  &__moon-mission-shadow-overlay {
    position: absolute;
    width: 1186px;
    height: 1208px;
    right: 0;
    bottom: 0;
    z-index: 8;
    background-image: url('../../img/moon-mission-intro/moon-shadow-overlay.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }

  &__ce1-lottie {
    position: absolute;
    z-index: 9;
    bottom: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
  }

  &__lightbox-title {
    font-family: 'NotoSansTC';
    font-size: 48px;
    font-weight: bold;
  }

  &__lightbox-content {
    font-family: 'NotoSansTC';
    font-weight: 300;
    font-size: 48px;
    line-height: 86px;
    letter-spacing: 0.1em;
    white-space: pre-line;
  }

  &__lightbox-content-thanks {
    margin: 0;
    padding: 0;
    font-family: 'NotoSansTC';
    font-weight: 300;
    font-size: 32px;
    letter-spacing: 0.1em;
    white-space: pre-line;
  }

  &__static-moon-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    pointer-events: none;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: all linear 500ms;

      &.visible {
        display: block;
        opacity: 1;
      }

      &.invisible {
        transition-delay: 300ms;
      }
    }
  }

  &__static-moon-container-ani {
    animation-name: showWhenAppear;
    animation-duration: $moonTransition;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: 1s;
  }

  &__li-title {
    text-decoration: underline;
    font-weight: 800;
  }

  &__ce-overlay-video {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    pointer-events: none;
  }

  &__light-box-content-en {
    font-family: "Exo2";

    .moon-mission-intro__lightbox-title,
    .moon-mission-intro__lightbox-content,
    .moon-mission-intro__lightbox-content-thanks {
      font-family: "Exo2";
      letter-spacing: 0;
    }
  }

  &__lottie-balls {
    position: absolute;
    top: -61px;
    pointer-events: none;
    opacity: 0;
    transition: all linear 500ms;

    &.active {
      opacity: 1;
    }
    
    &.y2007 {
      left: 200px;
    }

    &.y2010 {
      left: 385px;
    }

    &.y2013 {
      left: 550px;
    }

    &.y2014 {
      left: 730px;
    }

    &.y2018 {
      left: 910px;
    }

    &.y2020 {
      left: 1080px;
    }
  }
}
